import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import LeadsTable from "../../components/leads-table";
import useFetchData from "../../components/use-fetch-data";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AdminLayout from "../../components/admin-layout";
import BrokerMultiStepForm from "../../components/broker-multi-step-form";
import AddBrokerSection from "../../components/add-broker-section";
import withRoleBasedRedirect from "../../components/with-role-based-redirects";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const AddBrokerPage = () => {
	return (
		<AdminLayout>
			<GatsbySeo title="Add Broker" language="en" noindex nofollow />
			<Row className="bg-med-grey">
				<Col>
					<AddBrokerSection />
				</Col>
			</Row>
		</AdminLayout>
	);
};

export default withRoleBasedRedirect(AddBrokerPage, {
	requiredRole: "Super Admin",
});
